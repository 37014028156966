<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Panduan</div>
            </b-col>
            <b-col>
              <div align="right" v-if="allowCreate()">
                <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                >
              </div>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="lg"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Panduan" label-for="panduan">
                          <b-form-input
                            id="panduan"
                            v-model="form.name"
                            placeholder="Panduan"
                          />
                        </b-form-group>
                        <!-- <b-form-group label="Tipe" label-for="Tipe">
                          <b-form-select
                            v-model="form.type"
                            :options="optTipe"
                            disabled
                          />
                        </b-form-group> -->
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Status" label-for="status">
                          <b-form-radio
                            v-model="form.status"
                            name="some-radios"
                            value="1"
                            class="custom-control-success"
                          >
                            Aktif
                          </b-form-radio>
                          <b-form-radio
                            v-model="form.status"
                            name="some-radios"
                            value="0"
                            class="custom-control-danger"
                          >
                            Tidak Aktif
                          </b-form-radio>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group label="Konten" label-for="Konten">
                          <quill-editor
                            id="Konten"
                            v-model="form.content"
                          /> </b-form-group
                      ></b-col>
                    </b-row>
                  </b-form>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.prevent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="panduanData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    v-if="allowUpdate() || allowDelete()"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="allowUpdate()"
                      @click="ModalUbah(row.item)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="allowDelete()"
                      @click="ModalHapus(row.item)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>Hapus</span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <!-- <b-button variant="outline-info" class="btn-icon" @click="ModalUbah(row.item)"> <feather-icon
                      icon="EditIcon" /></b-button>
                  <b-button variant="outline-danger" class="btn-icon" @click="ModalHapus(row.item)"> <feather-icon
                      icon="TrashIcon" /></b-button> -->
                </template>
                <template #cell(show_details)="row">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                  >
                    <span class="vs-checkbox">
                      <span class="vs-checkbox--check">
                        <i class="vs-icon feather icon-check" />
                      </span>
                    </span>
                    <span class="vs-label">{{
                      row.detailsShowing ? "Hide" : "Show"
                    }}</span>
                  </b-form-checkbox>
                </template>
                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col class="mb-1">
                        <strong>Konten : </strong><br />
                        <span v-html="row.item.content" disabled />
                      </b-col>
                    </b-row>

                    <b-button
                      size="sm"
                      variant="outline-secondary"
                      @click="row.toggleDetails"
                    >
                      Hide Details
                    </b-button>
                  </b-card>
                </template>
                <!-- A custom formatted column -->
                <!-- <template #cell(Popularity)="data">
                <b-progress
                  :value="data.value.value"
                  max="100"
                  :variant="data.value.variant"
                  striped
                />
              </template> -->

                <template #cell(content)="data">
                  <!-- <quill-editor theme="bubble" v-model="data.item.content" /> -->
                  <div v-html="data.item.content" />
                </template>

                <!-- A virtual composite column -->
                <!-- <template #cell(price)="data">
                {{ "$" + data.value }}
              </template> -->

                <!-- Optional default data cell scoped slot -->
                <template #cell(status)="data">
                  <b-badge v-if="data.item.status == 1" variant="success">
                    Aktif
                  </b-badge>
                  <b-badge v-if="data.item.status == 0" variant="danger">
                    Tidak Aktif
                  </b-badge>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadio,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    ToastificationContent,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      panduanData: [],
      form: {
        id: null,
        name: null,
        content: null,
        type: "guide",
        status: 1,
      },
      //select
      optTipe: [
        { value: "guide", text: "Panduan" },
        // { value: "faq", text: "FAQ" },
      ],
      optStatus: [
        { value: "1", text: "Aktif" },
        { value: "0", text: "Tidak Aktif" },
        // { value: "faq", text: "FAQ" },
      ],
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "show_details", label: "#" },
        { key: "index", label: "No" },
        { key: "name", label: "Panduan", sortable: true },
        // { key: "content", label: "Konten", sortable: true },
        // { key: "type", label: "Tipe", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Panduan" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Panduan",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    tutupModal() {
      this.Modal = false;
      this.resetForm();
      this.getData();
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        content: null,
        type: "guide",
        status: 1,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Panduan";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Panduan";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          const payload = {
            id: data.id,
            fungsi: 1,
          };
          this.$store
            .dispatch("panduan/createUpdateDelete", [payload])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }

      let content = this.form.content;
      content = content.replaceAll("style=", "style2=");
      let payload = {
        name: this.form.name,
        status: this.form.status,
        content,
        type: this.form.type,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("panduan/createUpdateDelete", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.name = null;
          this.form.content = null;
          this.getData();
          this.displaySuccess({
            text: "Panduan berhasil di" + this.activeAction,
          });
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        type: "guide",
      };
      this.$store
        .dispatch("panduan/index", payload)
        .then((response) => {
          this.loading = false;
          let panduanData = response.data.data;
          this.panduanData = panduanData;
          this.totalRows = this.panduanData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
